import React, { useEffect, useState } from 'react';
import back from "../../Assets/Image/corporate/back.png";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import users from "../../Assets/Image/corporate/user.png";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { Circles } from 'react-loader-spinner';
import { addEmployees, getCompanyAddress } from '../../Services/ApiServices';
import { newName } from '../../Services/Api';

function AddEmployee() {
    const navigate = useNavigate();
    const [img, setImg] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [phoneDetails, setPhoneDetails] = useState({
        isoCode: '',
        countryCode: '',
        phoneNumber: ''
    });

    useEffect(() => {
        handleAddressList()
    }, [])


    const handleAddressList = () => {
        const data = JSON.parse(localStorage.getItem("CORPORATE_INFO"))
        getCompanyAddress(data.companyId)
            .then((res) => {
                if (res.status == 200) {
                    const data = res.data.info
                    setAddressList(data.companyAddresses)
                }
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
            })
    }


    const noSpecialChars = /^[^<>/?]*$/;
    const onlyNumbers = /^[0-9]*$/;

    const validationSchema = Yup.object({
        employeeNumber: Yup.string()
            .matches(onlyNumbers, '(Only numbers are allowed)')
            .matches(noSpecialChars, '(Special characters are not allowed)')
            .required('(Required)'),
        branch: Yup.string()
            .matches(noSpecialChars, '(Special characters are not allowed)')
            .required('(Required)'),
        firstName: Yup.string()
            .matches(noSpecialChars, '(Special characters are not allowed)')
            .required('(Required)'),
        lastName: Yup.string()
            .matches(noSpecialChars, '(Special characters are not allowed)')
            .required('(Required)'),
        jobTitle: Yup.string()
            .matches(noSpecialChars, '(Special characters are not allowed)')
            .required('(Required)'),
        employeeId: Yup.string()
            .matches(noSpecialChars, '(Special characters are not allowed)')
            .required('(Required)'),
        dateOfBirth: Yup.date().required('(Required)'),
        gender: Yup.string().required('(Required)'),
        phone: Yup.string()
            .matches(onlyNumbers, '(Only numbers are allowed)')
            .matches(noSpecialChars, '(Special characters are not allowed)')
            .required('(Required)'),
        email: Yup.string()
            .email('Invalid email address')
            .matches(
                /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                '(Invalid email address)'
            )
            .matches(noSpecialChars, '(Special characters are not allowed)')
            .required('(Required)'),
        fitnessStatus: Yup.string()
            .matches(noSpecialChars, '(Special characters are not allowed)')
            .required('(Required)'),
        image: Yup.mixed()
            .required('(Required)')
            .test(
                'fileSize',
                'File size is too large',
                value => value && value.size <= 5242880 // 5 MB
            )
            .test(
                'fileFormat',
                'Unsupported file format',
                value => value && ['image/jpeg', 'image/png'].includes(value.type)
            ),
    });

    const formik = useFormik({
        initialValues: {
            employeeNumber: '',
            branch: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            employeeId: '',
            dateOfBirth: null,
            gender: '',
            phone: '',
            email: '',
            fitnessStatus: '',
            image: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsLoading(true)
            console.log(values);
            const rawPhone = phoneDetails.phoneNumber.replace(phoneDetails.countryCode, "");
            const age = formik.values.dateOfBirth ? Math.floor((new Date() - new Date(formik.values.dateOfBirth).getTime()) / 3.15576e+10) : ''
            const company = JSON.parse(localStorage.getItem("CORPORATE_INFO"))
            const formData = new FormData()
            formData.append("firstName", values.firstName)
            formData.append("lastName", values.lastName)
            formData.append("isoCode", phoneDetails.isoCode)
            formData.append("countryCode", `+${phoneDetails.countryCode}`)
            formData.append("phoneNumber", rawPhone)
            formData.append("employeeNo", values.employeeNumber)
            formData.append("addressId", values.branch)
            formData.append("emailId", values.email)
            formData.append("idNumber", values.employeeId)
            formData.append("dob", moment(values.dateOfBirth).utc().format("YYYY-MM-DD"))
            formData.append("employeeJobTitle", values.jobTitle)
            formData.append("employeeAge", age)
            formData.append("employeeGender", values.gender)
            formData.append("employeeFitnessStatus", values.fitnessStatus)
            formData.append("profilePic", values.image)
            formData.append("companyId", company.companyId)

            addEmployees(formData)
                .then((res) => {
                    if (res.status == 200) {
                        toast.success(res.data.message)
                        navigate("/patients");
                        setIsLoading(false)
                    }
                })
                .catch((err) => {
                    if (err.response.status == 422) {
                        const errs = err.response.data
                        toast.error(errs.errors[0].msg)
                    } else {
                        toast.error(err.response.data.message);
                    }
                    setIsLoading(false)
                });
        },
    });

    const handlePhoneChange = (value, country, e, formattedValue) => {
        formik.setFieldValue('phone', value);
        setPhoneDetails({
            isoCode: country.countryCode,
            countryCode: country.dialCode,
            phoneNumber: value
        });
    };

    return (
        <div className='md:m-4 m-1'>
            <div className='flex items-center cursor-pointer fontNew my-3'>
                <img src={back} onClick={() => { navigate("/patients") }} className='w-4' alt="" />
                <p onClick={() => { navigate("/patients") }} className='text-base font-medium ms-3'>Go back</p>
            </div>

            <div className='bg-white sm:p-6 p-4 rounded-lg fontNew'>
                <form onSubmit={formik.handleSubmit} className="space-y-6">
                    <h2 className="text-xl font-semibold sm:mx-10 mx-0">New {newName}</h2>
                    <div className='grid grid-cols-12'>
                        <div className='md:mx-10 mx-0 md:my-0 my-3 md:col-span-6 col-span-12'>
                            <div className='flex text-sm font-medium mb-2'>
                                <label className='mr-2' htmlFor="employeeNumber">{newName} Number </label>
                                {formik.touched.employeeNumber && formik.errors.employeeNumber ? (
                                    <div className="text-red-500">{formik.errors.employeeNumber}</div>
                                ) : null}
                            </div>
                            <input
                                id="employeeNumber"
                                name="employeeNumber"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.employeeNumber}
                                className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                            />
                        </div>
                        <div className='md:mx-10 mx-0 md:my-0 my-3 md:col-span-6 col-span-12'>
                            <div className='flex text-sm font-medium mb-2'>
                                <label className='mr-2' htmlFor="branch">Branch</label>
                                {formik.touched.branch && formik.errors.branch ? (
                                    <div className="text-red-500">{formik.errors.branch}</div>
                                ) : null}
                            </div>
                            <select
                                id="branch"
                                name="branch"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.branch}
                                className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                            >
                                <option label="Select">Select</option>
                                {
                                    addressList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.addressId}>{`${item.aptAddress}, ${item.streetAddress}, ${item.city}, ${item.state}-${item.zipCode}`}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div>
                        <h2 className="text-xl font-semibold mx-10">{newName} Details</h2>
                        <div className='flex items-center md:m-10 my-4'>
                            {img ? (
                                <img src={img} className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] rounded-full object-cover' alt="" />
                            ) : (
                                <img src={users} className='2xl:w-[106px] 2xl:h-[106px] w-[88px] h-[88px] rounded-full' alt="" />
                            )}
                            <div className='ms-5'>
                                <h4 className='text-base font-semibold'>Profile Image</h4>
                                <p className='text-xs my-2 text-[#9C9DA9]'>Png, JPG, up to 5 MB</p>
                                <div className='mt-6'>
                                    <label htmlFor="upload-photo" className='bg-[#FBFBFB] text-[#5A5A5A] border-dashed border-2 border-[#D9D9D9] py-3 px-5 rounded-lg text-sm font-normal'>Upload image</label>
                                    <input
                                        type="file"
                                        onChange={(event) => {
                                            formik.setFieldValue('image', event.currentTarget.files[0]);
                                            let imageData = URL.createObjectURL(event.currentTarget.files[0]);
                                            setImg(imageData);
                                        }}
                                        className='hidden'
                                        id='upload-photo'
                                        accept='image/*'
                                    />
                                    {formik.touched.image && formik.errors.image ? (
                                        <div className="text-red-500 mt-2">{formik.errors.image}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12">
                            <div className='md:col-span-6 col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="firstName">First Name</label>
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div className="text-red-500">{formik.errors.firstName}</div>
                                    ) : null}
                                </div>
                                <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                    className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                                />
                            </div>
                            <div className='md:col-span-6 col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="lastName">Last Name</label>
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div className="text-red-500">{formik.errors.lastName}</div>
                                    ) : null}
                                </div>
                                <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                    className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                                />
                            </div>
                            <div className='md:col-span-6 col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="jobTitle">Job Title</label>
                                    {formik.touched.jobTitle && formik.errors.jobTitle ? (
                                        <div className="text-red-500">{formik.errors.jobTitle}</div>
                                    ) : null}
                                </div>
                                <input
                                    id="jobTitle"
                                    name="jobTitle"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.jobTitle}
                                    className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                                />
                            </div>
                            <div className='md:col-span-6 col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="employeeId">{newName} ID</label>
                                    {formik.touched.employeeId && formik.errors.employeeId ? (
                                        <div className="text-red-500">{formik.errors.employeeId}</div>
                                    ) : null}
                                </div>
                                <input
                                    id="employeeId"
                                    name="employeeId"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.employeeId}
                                    className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                                />
                            </div>
                            <div className='md:col-span-6 col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="dateOfBirth">Date of Birth</label>
                                    {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                                        <div className="text-red-500">{formik.errors.dateOfBirth}</div>
                                    ) : null}
                                </div>
                                <div className="flex space-x-2">
                                    <DatePicker
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        autoComplete='off'
                                        selected={formik.values.dateOfBirth}
                                        onChange={date => formik.setFieldValue('dateOfBirth', date)}
                                        placeholderText="Select a date"
                                        onKeyDown={(e) => e.preventDefault()}
                                        dateFormat="yyyy-MM-dd"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        showMonthDropdown
                                        dropdownMode="select"
                                        className="border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                                    />
                                </div>
                            </div>
                            <div className='md:col-span-6 col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="age">Age (will be calculated from DOB)</label>
                                </div>
                                <input
                                    name="age"
                                    type="text"
                                    value={formik.values.dateOfBirth ? Math.floor((new Date() - new Date(formik.values.dateOfBirth).getTime()) / 3.15576e+10) : ''}
                                    className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                                    readOnly
                                />
                            </div>
                            <div className='col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="gender">{newName} Gender</label>
                                    {formik.touched.gender && formik.errors.gender ? (
                                        <div className="text-red-500">{formik.errors.gender}</div>
                                    ) : null}
                                </div>
                                <div role="group" aria-labelledby="my-radio-group" className="space-x-4 flex">
                                    <label className={`py-2 px-4 text-sm rounded-full flex justify-center w-30 ${formik.values.gender === 'MALE' ? 'bg-[#029D79] text-white' : 'bg-white text-black border-[#989898] border-[1px]'}`}>
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="MALE"
                                            onChange={formik.handleChange}
                                            checked={formik.values.gender === 'MALE'}
                                            className="mr-3 gen-radio"
                                        />
                                        <p>Male</p>
                                    </label>
                                    <label className={`py-2 px-4 text-sm rounded-full flex justify-center w-30 ${formik.values.gender === 'FEMALE' ? 'bg-[#029D79] text-white' : 'bg-white text-black border-[#989898] border-[1px]'}`}>
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="FEMALE"
                                            onChange={formik.handleChange}
                                            checked={formik.values.gender === 'FEMALE'}
                                            className="mr-3 gen-radio"
                                        />
                                        <p>Female</p>
                                    </label>
                                </div>
                            </div>
                            <div className='md:col-span-6 col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="phone">{newName} Phone No.</label>
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div className="text-red-500">{formik.errors.phone}</div>
                                    ) : null}
                                </div>
                                <div className='col-span-12 mx-2 my-3'>
                                    <PhoneInput
                                        country={"za"}
                                        className='border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]'
                                        value={formik.values.phone}
                                        onChange={handlePhoneChange}
                                        inputProps={{ name: 'phone', required: true, autoFocus: true }}
                                    />
                                </div>
                            </div>
                            <div className='md:col-span-6 col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="email">{newName} Email</label>
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="text-red-500">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                                />
                            </div>
                            <div className='md:col-span-6 col-span-12 md:mx-10 mx-0 my-3'>
                                <div className='flex text-sm font-medium mb-2'>
                                    <label className='mr-2' htmlFor="fitnessStatus">Fitness Status</label>
                                    {formik.touched.fitnessStatus && formik.errors.fitnessStatus ? (
                                        <div className="text-red-500">{formik.errors.fitnessStatus}</div>
                                    ) : null}
                                </div>
                                <input
                                    id="fitnessStatus"
                                    name="fitnessStatus"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fitnessStatus}
                                    className="input border-[1px] py-2 px-3 w-full rounded-md outline-[#029D79]"
                                />
                            </div>
                        </div>
                    </div>

                    <button type="submit" className='btn btn-primary md:mx-10 mx-0 bg-[#00987C] w-[150px] h-[42px] rounded-lg text-white text-sm' disabled={isLoading}>
                        {
                            isLoading ?
                                <div className='flex justify-center'>
                                    <Circles
                                        height="23"
                                        width="23"
                                        color="#fff"
                                        ariaLabel="circles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                                :
                                "Save"
                        }
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddEmployee;
