import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import add from "../../Assets/Image/corporate/add.png"
import search from "../../Assets/Image/corporate/Search.png"
import left from "../../Assets/Image/corporate/leftbtn.png"
import right from "../../Assets/Image/corporate/rightbtn.png"
import color1 from "../../Assets/Image/corporate/color1.png"
import color2 from "../../Assets/Image/corporate/color2.png"
import color3 from "../../Assets/Image/corporate/color3.png"
import color4 from "../../Assets/Image/corporate/color4.png"
import color5 from "../../Assets/Image/corporate/color5.png"
import FormSelect from '../../Base-Component/FormSelect/FormSelect';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'

function Calender() {

    const navigate = useNavigate()
    const [selectStatus, setSelectStatus] = useState(1)
    const [searchData, setSearchData] = useState("")
    const [calenderData, setCalenderData] = useState(
        [
            {
                'title': 'All Day Event very long title',
                'allDay': true,
                'start': new Date(2024, 5, 0),
                'end': new Date(2024, 5, 1)
            },
            {
                'title': 'Long Event',
                'start': new Date(2024, 3, 7),
                'end': new Date(2024, 3, 10)
            },

            {
                'title': 'DTS STARTS',
                'start': new Date(2024, 5, 13),
                'end': new Date(2024, 5, 20)
            },

            {
                'title': 'DTS ENDS',
                'start': new Date(2024, 10, 6, 0, 0, 0),
                'end': new Date(2024, 10, 13, 0, 0, 0)
            },

            {
                'title': 'Some Event',
                'start': new Date(2024, 3, 9, 0, 0, 0),
                'end': new Date(2024, 3, 9, 0, 0, 0)
            },
            {
                'title': 'Conference',
                'start': new Date(2024, 5, 11),
                'end': new Date(2024, 5, 13),
                desc: 'Big conference for important people'
            },
            {
                'title': 'Meeting',
                'start': new Date(2024, 3, 12, 10, 30, 0, 0),
                'end': new Date(2024, 3, 12, 12, 30, 0, 0),
                desc: 'Pre-meeting meeting, to prepare for the meeting'
            },
            {
                'title': 'Lunch',
                'start': new Date(2024, 3, 12, 12, 0, 0, 0),
                'end': new Date(2024, 3, 12, 13, 0, 0, 0),
                desc: 'Power lunch'
            },
            {
                'title': 'Meeting',
                'start': new Date(2024, 3, 12, 14, 0, 0, 0),
                'end': new Date(2024, 3, 12, 15, 0, 0, 0)
            },
        ]
    )

    moment.locale("en-GB");
    const localizer = momentLocalizer(moment);

    const token = localStorage.getItem("corporate_token");
    useEffect(() => {
        if (!token) {
            navigate("/corporatelogin")
        }
    }, [token])

    return (
        <>
            <div className='grid grid-cols-12 fontNew bg-white md:m-4 m-1 md:p-5 p-3  rounded-lg'>
                <div className='col-span-12 mx-3'>
                    <div className='flex justify-between items-center mb-4 border-b pb-4'>
                        <div>
                            <p className='text-gray-400 font-medium'>Today</p>
                            <p className='sm:text-lg text-base font-medium'>7 March 2024</p>
                        </div>
                        <div>
                            <button className='bg-[#EA5F5F] text-sm text-white flex items-center py-2 px-3 rounded-lg'>
                                <img src={add} className='w-6 mr-2' alt="" />New Requests
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-wrap justify-between items-center mb-2'>
                        <div className='flex flex-wrap items-center'>
                            <div className='flex xl:w-70 sm:w-52 w-full h-10 xl:px-4 px-2 rounded-xl border-[1px] border-slate-300 my-3 items-center hover:border-[#00987C]'>
                                <input
                                    type="text"
                                    required
                                    className='bg-transparent border-none outline-none w-full h-8 text-sm fontNew'
                                    placeholder='Password'
                                    name='password'
                                    value={searchData}
                                    onChange={(e) => setSearchData(e.target.value)}
                                />
                                <img src={search} className='p-2 lg:w-10 w-8 cursor-pointer' alt="" />
                            </div>
                            <FormSelect id="category" name="fuel_type" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)} className="sm:ms-3 sm:mb-0 mb-3 ms-0 outline-[#00987C] select-arrow-hidden ps-5 pl-0 pb-0 pt-0 text-sm rounded-xl font-medium xl:w-40 sm:w-36 w-full h-10 outline-0 border-[1px] border-slate-300">
                                <option >All</option>
                                <option value={1}>Pending</option>
                                <option value={2}>Confirmed</option>
                                <option value={3}>In-Progress</option>
                                <option value={4}>Completed</option>
                                <option value={5}>Cancelled</option>
                            </FormSelect>
                        </div>
                        <div className='flex items-center justify-between sm:w-auto w-full'>
                            <p className='xl:text-[22px] text-lg font-medium sm:mx-4 mx-0'>March 2024</p>
                            <div className='flex items-center'>
                                <img src={left} className='xl:w-14 w-12 px-2' alt="" />
                                <img src={right} className='xl:w-14 w-12 px-2' alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap items-center my-6'>
                        <div className='flex items-center mx-3'>
                            <img src={color1} className='w-4' alt="" />
                            <p className='font-medium ms-2'>Pending</p>
                        </div>
                        <div className='flex items-center mx-3'>
                            <img src={color2} className='w-4' alt="" />
                            <p className='font-medium ms-2'>Confirmed</p>
                        </div>
                        <div className='flex items-center mx-3'>
                            <img src={color3} className='w-4' alt="" />
                            <p className='font-medium ms-2'>In Progress</p>
                        </div>
                        <div className='flex items-center mx-3'>
                            <img src={color4} className='w-4' alt="" />
                            <p className='font-medium ms-2'>Completed</p>
                        </div>
                        <div className='flex items-center mx-3'>
                            <img src={color5} className='w-4' alt="" />
                            <p className='font-medium ms-2'>Cancelled</p>
                        </div>
                    </div>
                    <Calendar
                        localizer={localizer}
                        events={calenderData}
                        step={60}
                        defaultDate={new Date()}
                        popup={true}
                        style={{ height: 600 }}
                    />
                </div>
            </div>
        </>
    )
}

export default Calender