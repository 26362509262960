import React, { useEffect, useRef, useState } from 'react'
import welorounds from "../../Assets/Image/Healthworker/weloround.png"
import send from "../../Assets/Image/Healthworker/sendbtn.png"
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { getChatList, getChatMessageList, sendMessages } from '../../Services/ApiServices';
import { IMG_URL } from '../../Services/Api';
import moment from 'moment';
import { Socket } from '../../Assets/Socket/Socket';

function Messages() {

    const navigate = useNavigate()
    const scrollRef = useRef(null);

    const userId = JSON.parse(localStorage.getItem("CORPORATE_INFO"))

    const [loader, setLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isFlag, setIsFlag] = useState(0)
    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState("")
    const [currPage, setCurrPage] = useState(1);
    const [totalPages, setTotalPages] = useState("")
    const [isIndex, setIsIndex] = useState("")
    const [isChatId, setIsChatId] = useState("")
    const [isOtherId, setIsOtherId] = useState("")
    const [textValue, setTextValue] = useState("")

    const [messageList, setMessageList] = useState([])
    const [chatList, setChatList] = useState([])

    const token = localStorage.getItem("corporate_token")

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [chatList])

    useEffect(() => {
        if (!token) {
            navigate("/corporatelogin")
        }
    }, [])

    useEffect(() => {
        // Socket.on("connect", () => {
        //     console.log(Socket.connected, Socket.id);
        // });

        // if (Socket.connected != true) {
        //     Socket.emit("socket_register", {
        //         userId: userId.userId,
        //     });
        // }

        Socket.on("count_update", (data) => updatecount(data));

        Socket.on("new_message", (data) => {
            console.log("new_message", data);
            if (isChatId) {
                if (isChatId === data.chatId) {
                    setChatList([...chatList, data]);
                } else {
                    setChatList([...chatList]);
                }
            }
        });

    }, [Socket, chatList])

    const updatecount = (datas) => {
        let is_update = 0;

        setMessageList((prevChats) => {
            const updatedChats = prevChats.map((item) => {
                if (item.chatId === datas.chatId) {
                    is_update = 1;
                    return datas;
                }
                return item;
            });
            return is_update ? updatedChats : [datas, ...prevChats];
        });
    }

    const handleGetChatList = () => {
        setLoader(true)
        let param = {
            pageNo: pageNo,
        }
        getChatList(param)
            .then((res) => {
                if (res.status == 200) {
                    const data = res.data.info;
                    setMessageList(data)
                    setTotalPage(res.data.totalPage)
                    Socket.emit("join_room", {
                        userId: userId.userId,
                        chatId: data[0].chatId,
                    });
                    let obj = {
                        userId: userId.userId,
                        chatId: data[0].chatId
                    }
                    localStorage.setItem("CorpoChat", JSON.stringify(obj))
                    setIsFlag(1);
                    setIsIndex(0);
                    setIsChatId(data[0].chatId)
                    setIsOtherId(data[0].otherId)
                    handleChatMessage(data[0].chatId)
                }
                setLoader(false)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setLoader(false)
            })
    }

    useEffect(() => {
        handleGetChatList()
    }, [])

    const handleChatMessage = (id) => {
        setIsLoading(true)
        let param = {
            pageNo: currPage,
            chatId: id
        }
        getChatMessageList(param)
            .then((res) => {
                if (res.status == 200) {
                    const data = res.data.info.reverse();
                    if (currPage != 1) {
                        setChatList([...chatList, data])
                    } else {
                        setChatList(data)
                    }
                    setTotalPages(res.data.totalPage)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setIsLoading(false)
            })
    }

    const sendNewMessage = (e) => {
        e.preventDefault()
        let param = {
            otherId: isOtherId,
            chatId: isChatId,
            messageType: "TEXT",
            messageText: textValue
        }
        sendMessages(param)
            .then((res) => {
                if (res.status == 200) {
                    setTextValue("")
                }
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setIsLoading(false)
            })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (textValue.length) {
                sendNewMessage(e);
            }
        }
    };

    return (
        <div className='fontNew md:m-4 m-1'>
            <div className="grid grid-cols-12 gap-5">
                {/* <div className="md:col-span-4 col-span-12">
                    {
                        messageList.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className='flex bg-white py-4 sm:px-5 px-3 rounded-lg cursor-pointer'
                                    onClick={() => {
                                        if (item.chatId != isChatId) {

                                        }

                                        let chatss = [...messageList];
                                        chatss[index].unreadCount = 0;
                                    }}
                                >
                                    <img src={item.profilePic ? IMG_URL + item.profilePic : welorounds} crossOrigin='anonymous' className='w-[50px] h-[50px] object-cover rounded-full' alt="" />
                                    <div className='ms-4 w-full flex items-center justify-between'>
                                        <div className='w-full'>
                                            <div className='flex items-center justify-between'>
                                                <p className='text-base font-medium'>{item.userName || ""}</p>
                                                <p className='text-[#9EA0A8]'>{moment(item.createdAt).fromNow()}</p>
                                            </div>
                                            <div className='flex items-center justify-between'>
                                                <p className='text-[#9EA0A8]'>{item.messageText || ""}</p>
                                                {item.unreadCount ? (
                                                    <p className='bg-[#00987C] w-6 h-6 rounded-full text-white flex items-center justify-center text-sm mt-2'>
                                                        {item.unreadCount}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> */}
                {
                    isFlag == 0 ?
                        <div className="md:col-span-12 col-span-12 bg-white py-4 sm:px-5 px-3 rounded-lg">
                            <div className='h-[80vh] flex items-center justify-center'>
                                <div className='text-center'>
                                    <div className='flex justify-center'>
                                        <img src={welorounds} className='w-18' alt="" />
                                    </div>
                                    <p className='mt-2'>Hey,</p>
                                    <p>Please select a chat to start messaging.</p>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="md:col-span-12 col-span-12 bg-white py-4 rounded-lg sm:px-5 px-3">
                            <div className='flex mb-3'>
                                <img src={messageList[isIndex].profilePic ? IMG_URL + messageList[isIndex].profilePic : welorounds} crossOrigin='anonymous' className='w-[50px] h-[50px] rounded-full object-cover' alt="" />
                                <div className='ps-4 w-full'>
                                    <p className='text-lg font-medium'>Welo Admin</p>
                                    <ul className='list-disc ps-4'>
                                        <li className='text-[#00987C] text-sm'>Online</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='overflow-y-scroll hide-scrollbar' style={{ overflowY: "scroll", display: "flex", flexDirection: "column-reverse" }}>
                                <div className='h-[70vh] flex items-end'>
                                    <div className='w-full'>
                                        {chatList.map((item, index) => {
                                            return (
                                                <div key={index} ref={scrollRef}>
                                                    {userId.userId === item.messageBy ? (
                                                        <div>
                                                            <div className='flex justify-end text-white my-2'>
                                                                <div className='bg-[#00987C] px-5 py-2 rounded-tl-xl rounded-br-xl rounded-bl-xl max-w-[50%]'>
                                                                    <p>{item.messageText || ""}</p>
                                                                </div>
                                                            </div>
                                                            <p className='text-gray-400 text-[13px] text-end'>
                                                                {moment(item.createdAt).format("h:mm a")}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <div className='ps-4'>
                                                            <div className='flex justify-start text-white my-2'>
                                                                <div className='bg-[#efefef] text-black px-5 py-2 rounded-tr-xl rounded-br-xl rounded-bl-xl max-w-[50%]'>
                                                                    <p>{item.messageText || ""}</p>
                                                                </div>
                                                            </div>
                                                            <p className='text-gray-400 text-[13px] text-start'>
                                                                {moment(item.createdAt).format("h:mm a")}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <form onSubmit={sendNewMessage} className=''>
                                <div className='flex items-center mt-3'>
                                    <input type="text" onKeyDown={handleKeyDown} required placeholder='Type...' value={textValue} onChange={(e) => setTextValue(e.target.value)} className='w-full bg-[#F1F1F1] rounded-full outline-none px-7 h-[50px]' />
                                    {
                                        textValue ?
                                            <button type='submit'>
                                                <img src={send} className='w-[50px] ms-3' alt="" />
                                            </button>
                                            : ""
                                    }
                                </div>
                            </form>
                        </div>
                }
            </div>
        </div>
    )
}

export default Messages